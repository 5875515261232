<script>
    import {PATH} from "@/constants";
    export default {
        name: "CodeOptions",
        data() {
            return {
                reasonCodeOptions: [],
                doneReasonCodeOptions: [],
                asReceiptTypeOptions: [],
                workorderStateOptions: [],
                workorderStaffOptions: [],
                // CMS 상태
                cmsStateOptions: [],
                // 세금계산서
                autoIssueYnOptions: [],
                // 계약상태
                useYnOptions: [],
                // 거래처 등급
                accLevelTypeOptions: [],
                // 결제일 및 계산시작일
                billDayOnlyNumberOptions: [],
                // 장기미방문
                unvisitedMonthOptions: [],
                // 지도표시
                mapYnOptions: [],
                // 지불방법
                payTypeOptions: [],
                // 프린터 상태 코드
                printerStateOptions: [],
                // 무한잉크 여부
                unlimitedInkYnOptions: [],
                // 토너 사용 여부
                pmChkTonerOptions: [],
                // 드럼 사용 여부
                pmChkDrumOptions: [],
                // 계약 여부
                isPrinterContractOptions: [],
                // 모니터링 여부
                isMonitoringPrinterOptions: [],
                // 출고 여부
                installStatusOptions: [],
                // 장치 구분
                deviceTypeOptions: [],
                // 접속 상태
                connectionStatusOptions: [],

                asOptions: {
                    reasonCodeOptions: undefined,
                    doneReasonCodeOptions: undefined,
                    asReceiptTypeOptions: undefined,
                    workorderStateOptions: undefined,
                    workorderStaffOptions: undefined,
                    ShowOptions: [
                        {
                            codeValue: this.t('보기'), codeKey: false
                        },
                        {
                            codeValue: this.t('숨기기'), codeKey: true
                        },
                    ],
                    dateOptions:  [
                      {
                        codeValue: this.t('예약일시 기준 검색 보기'), codeKey: true
                      },
                      {
                        codeValue: this.t('접수일시 기준 검색 보기'), codeKey: false
                      },
                    ],
                    asDateOptions:  [
                      {
                        codeValue: this.t('전체'), codeKey: null
                      },
                      {
                        codeValue: this.t('당일건'), codeKey: 'T'
                      },
                      {
                        codeValue: this.t('예약건'), codeKey: 'F'
                      },
                    ],
                },
            }
        },
        created() {
        },
        methods: {
          /**
           * 거래처 등급 로드
           */
          async loadAccountLevelCode() {
            try {
              const response = await this.$request(PATH.RENTALCOMPANY_CODES.BASE)
                  .setObject({ codeType: 'ACC_LEVEL_TYPE' })
                  .enqueue()
              this.accLevelTypeOptions = response.data
            } catch (e) {
              // this.accountLevelOptions = [{ title: '일반', value: '00' }]
            }
          },
            /**
             * AS접수증상 로드
             */
            async loadOptionsForReasonCode() {
                try {
                    const response = await this.loadRentalcompanyCodes("AC");
                  const result = response.data.map((e) => {
                    e.codeValue = this.t(e.codeValue)
                    return e
                  })
                    this.reasonCodeOptions = result;
                    this.asOptions.reasonCodeOptions = result;
                } catch (e) {
                    //do nothing.
                }
            },

            /**
             * AS처리유형 로드
             */
            async loadOptionsForDoneReasonCode() {
                try {
                    const response = await this.loadRentalcompanyCodes("AS");
                    const result = response.data.map((e) => {
                      e.codeValue = this.t(e.codeValue)
                      return e
                    })
                    this.doneReasonCodeOptions = result;
                    this.asOptions.doneReasonCodeOptions = result;
                } catch (e) {
                    //do nothing.
                }
            },

            /**
             * 접수구분 로드
             */
            async loadOptionsForAsReceiptType() {
                try {
                    const response = await this.loadRentalcompanyCodes("ART");
                  const result = response.data.map((e) => {
                    e.codeValue = this.t(e.codeValue)
                    return e
                  })
                    this.asReceiptTypeOptions = result;
                    this.asOptions.asReceiptTypeOptions = result;
                } catch (e) {
                    //do nothing.
                }
            },

            /**
             * AS진행상태 로드
             */
            async loadOptionsForWorkorderState() {
                try {
                    const response = await this.loadCodes("ST");
                  const result = response.data.map((e) => {
                    e.codeValue = this.t(e.codeValue)
                    return e
                  })
                    this.workorderStateOptions = result;
                    this.asOptions.workorderStateOptions = result;
                } catch (e) {
                    //do nothing.
                }
            },

            /**
             * AS기사 로드
             */
            async loadOptionsForWorkorderStaff() {
                try {
                    const response = await this.$request(PATH.USER.EMPLOYEE.LIST)
                        .post()
                        .enqueue();
                    this.workorderStaffOptions = response.data;
                    this.asOptions.workorderStaffOptions = response.data;
                } catch (e) {
                    //do nothing.
                }
            },

            async loadCodes(codeType) {
                return this.$request(PATH.OPTIONS.BASE)
                    .setObject({codeType: codeType.toUpperCase()})
                    .enqueue();
            },

            async loadRentalcompanyCodes(codeType) {
                return this.$request(PATH.RENTALCOMPANY_CODES.BASE)
                    .setObject({codeType: codeType})
                    .enqueue();
            },


            /**
             * CMS 상태 로드
             */
            async loadCmsStateOptions() {
                // CMS 상태
                this.cmsStateOptions = [
                    {
                        codeValue: this.t('사용중'), codeKey: "Y"
                    },
                    {
                        codeValue: this.t('미사용'), codeKey: "N",
                    },
                ];
            },
            /**
             * 세금계산서 로드
             */
            async loadAutoIssueYnOptions() {
                this.autoIssueYnOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {codeValue: this.t('사용'), codeKey: "Y"},
                    {codeValue: this.t('미사용'), codeKey: "N"}
                ];
            },
            /**
             * 계약상태 로드
             */
            async loadUseYnOptions() {
                this.useYnOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {codeValue: this.t('계약중'), codeKey: "Y"},
                    {codeValue: this.t('계약 해지'), codeKey: "N"}
                ];
            },
            /**
             * 결제일 로드
             */
            async loadBillDayOnlyNumberOptions() {
                this.billDayOnlyNumberOptions = [
                    {codeValue: '1', codeKey: '1'},
                    {codeValue: '2', codeKey: '2'},
                    {codeValue: '3', codeKey: '3'},
                    {codeValue: '4', codeKey: '4'},
                    {codeValue: '5', codeKey: '5'},
                    {codeValue: '6', codeKey: '6'},
                    {codeValue: '7', codeKey: '7'},
                    {codeValue: '8', codeKey: '8'},
                    {codeValue: '9', codeKey: '9'},
                    {codeValue: '10', codeKey: '10'},
                    {codeValue: '11', codeKey: '11'},
                    {codeValue: '12', codeKey: '12'},
                    {codeValue: '13', codeKey: '13'},
                    {codeValue: '14', codeKey: '14'},
                    {codeValue: '15', codeKey: '15'},
                    {codeValue: '16', codeKey: '16'},
                    {codeValue: '17', codeKey: '17'},
                    {codeValue: '18', codeKey: '18'},
                    {codeValue: '19', codeKey: '19'},
                    {codeValue: '20', codeKey: '20'},
                    {codeValue: '21', codeKey: '21'},
                    {codeValue: '22', codeKey: '22'},
                    {codeValue: '23', codeKey: '23'},
                    {codeValue: '24', codeKey: '24'},
                    {codeValue: '25', codeKey: '25'},
                    {codeValue: '26', codeKey: '26'},
                    {codeValue: '27', codeKey: '27'},
                    {codeValue: '28', codeKey: '28'},
                    {codeValue: '29', codeKey: '29'},
                    {codeValue: '30', codeKey: '30'},
                    {codeValue: '31', codeKey: '31'},
                ];
            },

            /**
             * 장기미방문 옵션 로드
             */
            async loadUnvisitedMonthOptions() {
                // 장기 미방문
                this.unvisitedMonthOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {
                        codeValue: "1" + this.$translate("개월 경과"), codeKey: 1,
                    },
                    {
                        codeValue: "2" + this.$translate("개월 경과"), codeKey: 2,
                    },
                    {
                        codeValue: "3" + this.$translate("개월 경과"), codeKey: 3,
                    },
                    {
                        codeValue: "4" + this.$translate("개월 경과"), codeKey: 4,
                    },
                    {
                        codeValue: "5" + this.$translate("개월 경과"), codeKey: 5,
                    },
                    {
                        codeValue: "6" + this.$translate("개월 경과"), codeKey: 6,
                    },
                ];
            },


            /**
             * 지도표시 가능 여부 로드
             */
            async loadMapYnOptions() {
                this.mapYnOptions = [
                    {codeValue: this.t('가능'), codeKey: "Y"},
                    {codeValue: this.t('불가능'), codeKey: "N"}
                ];
            },
            /**
             * 지불방법 로드
             */
            async loadPayTypeOptions() {
                this.payTypeOptions = [
                    {codeValue: this.t('CMS_출금'), codeKey: "B"},
                    {codeValue: this.t('카드출금'), codeKey: "C"},
                    {codeValue: this.t('기타수납'), codeKey: "E"}
                ];
            },

            /**
             * 프린터 상태 코드 로드
             */
            async loadPrinterStateCodes() {
                // 실제로 서버에서 로드하는 코드로 대체해야 합니다.
                this.printerStateOptions = [
                    {codeValue: this.t('정상'), codeKey: "01"},
                    {codeValue: this.t('잉크부족'), codeKey: "02"},
                    {codeValue: this.t('프린터 오류'), codeKey: "03"},
                    {codeValue: this.t('센서 오류'), codeKey: "04"},
                    {codeValue: this.t('프린터 통신 오류'), codeKey: "05"},
                    {codeValue: this.t('장비 통신 오류'), codeKey: "08"},
                    {codeValue: this.t('알수 없는 오류'), codeKey: "99"}
                ];
            },

            /**
             * 무한 잉크 옵션 로드
             */
            async loadUnlimitedInkYnOptions() {
                this.unlimitedInkYnOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {codeValue: this.t('사용'), codeKey: "Y"},
                    {codeValue: this.t('미사용'), codeKey: "N"},
                ];
            },

            /**
             * 토너 옵션 로드
             */
            async loadPmChkTonerOptions() {
                this.pmChkTonerOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {codeValue: this.t('드럼 체크'), codeKey: "Y"},
                    {codeValue: this.t('드럼 미체크'), codeKey: "N"},
                ];
            },

            /**
             * 드럼 옵션 로드
             */
            async loadPmChkDrumOptions() {
                this.pmChkDrumOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {codeValue: this.t('드럼 체크'), codeKey: "Y"},
                    {codeValue: this.t('드럼 미체크'), codeKey: "N"},
                ];
            },

            /**
             * 계약 여부 옵션 로드
             */
            async loadIsPrinterContractOptions() {
                this.isPrinterContractOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {codeValue: this.t('계약중'), codeKey: "true"},
                    {codeValue: this.t('계약 해지'), codeKey: "false"},
                ];
            },

            /**
             * 모니터링 여부 옵션 로드
             */
            async loadIsMonitoringPrinterOptions() {
                this.isMonitoringPrinterOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {codeValue: this.t('모니터링중'), codeKey: "true"},
                    {codeValue: this.t('모니터링 중지'), codeKey: "false"},
                ];
            },

            /**
             *  출고 여부 옵션 로드
             */
            async loadInstallStatusOptions() {
                this.installStatusOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {codeValue: this.t('출고'), codeKey: "Y"},
                    {codeValue: this.t('출고전'), codeKey: "N"},
                ];
            },

            /**
             * 장치 구분 옵션 로드
             */
            async loadDeviceTypeOptions() {
                this.deviceTypeOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {codeValue: this.t('PC에이전트'), codeKey: "PC"},
                    {codeValue: this.t('실물콘솔'), codeKey: "DV"},
                ];
            },

            /**
             * 접속 상태 옵션 로드
             */
            async loadConnectionStatusOptions() {
                this.connectionStatusOptions = [
                    // {codeValue: this.t('전체'), codeKey: null},
                    {codeValue: this.t('접속중'), codeKey: "1"},
                    {codeValue: this.t('연결끊어짐'), codeKey: "0"},
                ];
            },
        },
    }
</script>