<template>
  <v-card
    class="ma-0"
    width="100%"
    flat
    style="padding: 0px 15px; border-bottom: 1px solid #ddd;"
    @click="onClick"
  >
    <!--    :color="{'red': item.isReservationIsOver }"-->
    <v-list-item
      two-line
      class="pa-0"
    >
      <v-list-item-content class="pa-0">
        <v-list-item-title class="text-left">
          {{ item.raw.printerModelName }}
        </v-list-item-title>
        <div class="d-flex justify-space-between align-baseline">
          <v-list-item-subtitle
            class="text-left"
          >
            {{ item.raw.printerSerialNo }}
          </v-list-item-subtitle>
        </div>
        <div>
          <div
            class="text-right"
            style="width: 40%; font-size: small"
          >
            <span>
              {{ item.raw.installLocation }}
            </span>
            <br>
            <span>
              {{ item.raw.installLocation2 }}
            </span>
          </div>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
    export default {
        name: "PrinterItemSimpleCard",
        components: {},
        props: {
            item: {type: Object, default: undefined},
        },
        computed: {
        },
        methods: {
          onClick() {
              this.$emit('pick', this.item.raw);
          },
        }
    }
</script>

<style scoped>
    .v-application .subtitle-2 {
        font-size: 0.875rem;
        font-weight: inherit !important;
        letter-spacing: 0.0071428571em !important;
        line-height: 1.375rem;
        font-family: "Roboto", sans-serif !important;
    }
</style>